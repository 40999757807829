<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <div class="tabs">
      <v-tabs
        v-model="tab"
        background-color="light primary"
        class="elevation-2"
        dark
      >
        <v-tab>{{ $t("COMPONENTS.CERTIFICATE") }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row>
                <!-- <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Продукт:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="products"
                        label="name"
                        v-model="certificate.rawProductId"
                        :value="certificate.rawProductId"
                        :reduce="products => products.id"
                        @input="getMaterials(certificate.rawProductId)"
                      ></vu-select>
                    </div>
                  </div>
                </v-col> -->

                <!-- <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Приемно-предавателен протокол:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="acceptanceActs"
                        label="number"
                        v-model="certificate.acceptanceActId"
                        :value="certificate.acceptanceActId"
                        :reduce="acceptanceActs => acceptanceActs.id"
                      ></vu-select>
                    </div>
                  </div>




                  <div class="form-group" v-if="certificate.acceptanceActId != null">
                    <label for="">
                      <span> Партида:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="batch = _.find(acceptanceActs, function(o) { return o.id == certificate.acceptanceActId; }).details"
                        label="batch"
                        v-model="certificate.batch"
                        :value="certificate.batch"
                        :reduce="batch => batch.batch"
                        @input="getMaterial( _.find(batch, function(o) { return o.batch == certificate.batch; }).rawProductId)"
                      ></vu-select>
                    </div>
                  </div>
                </v-col> -->
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Възложител:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="clients"
                        label="name"
                        v-model="certificate.clientId"
                        :value="certificate.clientId"
                        :reduce="clients => clients.id"
                      ></vu-select>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Материал:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="materials"
                        label="optionName"
                        v-model="certificate.materialId"
                        :value="certificate.materialId"
                        :reduce="materials => materials.id"
                        :disabled="isEdit && certificate.signedByQualified"
                      ></vu-select>
                    </div>
                  </div>
                </v-col>

                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Партидeн номер на производител:</span>
                    </label>
                    <div class="field-wrap">
                      <input
                        type="text"
                        class="form-control"
                        v-model="certificate.vendorBatch"
                      />
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Печатница:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="vendors"
                        label="name"
                        v-model="certificate.vendorId"
                        :value="certificate.vendorId"
                        :reduce="vendors => vendors.id"
                      ></vu-select>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Количество:</span>
                    </label>
                    <div class="field-wrap">
                      <input
                        type="text"
                        class="form-control"
                        v-model="certificate.inQuantity"
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Дата:</span>
                    </label>
                    <div class="field-wrap">
                      <datetime
                        zone="local"
                        value-zone="local"
                        type="date"
                        v-model="certificate.date"
                        input-class="form-control"
                      ></datetime>
                    </div>
                  </div>
                </v-col>

                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Дата на доставка</span>
                    </label>
                    <div class="field-wrap">
                      <datetime
                        zone="local"
                        value-zone="local"
                        type="date"
                        v-model="certificate.deliveryDate"
                        input-class="form-control"
                      ></datetime>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Заключение (Протокол)</span>
                    </label>
                    <div class="field-wrap">
                      <textarea
                        v-model="certificate.conclusionProtocol"
                        class="form-control"
                      ></textarea
                      ><br />
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" v-if="certificate.statusId === 2">
                  <div class="form-group">
                    <label for="">
                      <span> Заключение (Сертификат)</span>
                    </label>
                    <div class="field-wrap">
                      <textarea
                        v-model="certificate.conclusion"
                        class="form-control"
                      ></textarea
                      ><br />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <hr />
              <v-btn
                @click="save()"
                class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                >{{ $t("BUTTON.SAVE") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import moment from "moment";

import {
  GET_CERTIFICATE,
  SAVE_CERTIFICATE,
  UPDATE_CERTIFICATE
} from "@/store/certificates.module";

import { GET_ALL_PRINTING_HOUSES } from "@/store/printingHouses.module";

import { GET_ALL_PRODUCTS } from "@/store/products.module";

import { GET_ALL_ACCEPTANCEACTS } from "@/store/acceptanceActs.module";

import { GET_ALL_WAREHOUSES } from "@/store/warehouses.module";

import { GET_ALL_CERTIFICATE_TYPES } from "@/store/certificateTypes.module";

import { GET_DATA } from "@/store/formselect.module";

import { GET_ALL_CLIENTS } from "@/store/clients.module";

import { GET_ALL_MATERIALS } from "@/store/materials.module";

import { mapGetters, mapState } from "vuex";
// Vue.use(VueFormGenerator)
import _ from "lodash";

import { GET_FILES_BY_TYPE, GET_FILE } from "@/store/files.module";

import { APP_URL } from "@/common/config";

// import { GET_ALL_PRODUCTS } from "@/store/products.module";

export default {
  name: "createUpdate",

  components: {},
  computed: {
    ...mapGetters(["isLoadingCertificates"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    isEdit: function() {
      if (typeof this.$route.params.id !== "undefined") {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      error_messages: [],
      model: "certificates",
      tab: 0,
      isSubmitDisabled: true,
      vendfors: [],
      certificate: {
        clientId: null,
        acceptanceActId: null,
        certificateTypeId: 1,
        productId: null,
        batch: null,
        rawProductId: null,
        vendorId: null,
        inQuantity: null,
        materialId: null,
        statusId: null,
        // "userId": 1,
        date: moment().format("YYYY-MM-DD"),
        qualifiedPerson: null,
        note: "",
        conclusion: "",
        conclusionProtocol:
          "Опаковъчните материали отговарят на съпътстващата документация (ППП, експедиционно нареждане, заявка), извършено е пробовземане и предстои окачествяване на съответния ВОМ съгласно спецификация /МОЛЯ ПОПЪЛНЕТЕ/",
        deliveryDate: moment().format("YYYY-MM-DD")
      },
      vendors: [],
      warehouses: [],
      products: [],
      newDetail: {},
      files: [],
      acceptanceActs: [],
      certificateStatuses: [],
      types: [],
      materials: [],
      clients: [],
      material_details: [],
      materialTypes: []
    };
  },
  mounted() {
    let vm = this;

    _.delay(function() {
      var current_page = "Добави";
      if (typeof vm.$route.params.id !== "undefined") {
        current_page = "Промени";
      }
      vm.$store.dispatch(SET_BREADCRUMB, [
        {
          title: "Сертификат за освобождаване на опаковъчен материал",
          route: "/certificates"
        },
        { title: current_page }
      ]);
    }, 300);

    Promise.all([
      this.getMaterialTypes(),
      this.getMaterials(),
      this.getClients(),
      this.getVendors()
    ]).then(() => {
      if (typeof this.$route.params.id !== "undefined") {
        this.getData();
      }
    });
  },
  methods: {
    getActs: function() {
      let vm = this;
      this.error_messages = [];
      vm.$store
        .dispatch(GET_ALL_ACCEPTANCEACTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.acceptanceActs = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getMaterials: function() {
      let vm = this;

      this.error_messages = [];
      return vm.$store
        .dispatch(GET_ALL_MATERIALS)
        .then(data => {
          vm.$nextTick(function() {
            vm.materials = data.data;

            for (let i in vm.materials) {
              vm.materials[i].optionName =
                _.find(vm.materialTypes, function(o) {
                  return o.id == vm.materials[i].typeId;
                }).name +
                " - " +
                vm.materials[i].name;
            }
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getVendors: function() {
      let vm = this;
      this.error_messages = [];
      return vm.$store
        .dispatch(GET_ALL_PRINTING_HOUSES)
        .then(data => {
          vm.$nextTick(function() {
            vm.vendors = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getMaterialTypes: function() {
      let vm = this;
      let payload = {
        materialTypes: {
          fields: ["id", "name"],
          search: ""
        }
      };

      this.error_messages = [];

      return vm.$store
        .dispatch(GET_DATA, payload)
        .then(data => {
          vm.$nextTick(function() {
            vm.materialTypes = data.data.materialTypes;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getClients: function() {
      let vm = this;

      this.error_messages = [];
      return vm.$store
        .dispatch(GET_ALL_CLIENTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.clients = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    // getMaterial: function(id) {
    //   let vm = this;

    // let details = _.find(vm.materials, function(o) { return o.id == id; }).details;

    // if(vm.certificate.results.length > 0) {
    //   for(let i in details) {
    //     console.log(_.find(vm.certificate.results, function(o) { return o.propertyId == details[i].propertyId }))
    //     if(typeof _.find(vm.certificate.results, function(o) { return o.propertyId == details[i].propertyId }) == 'undefined')
    //     {
    //       details[i].answer = false;
    //     }
    //     else {
    //       details[i].answer = _.find(vm.certificate.results, function(o) { return o.propertyId == details[i].propertyId }).answer;
    //     }
    //   }
    // }

    // vm.certificate.results =  details;

    // },

    getTypes: function() {
      let vm = this;

      this.error_messages = [];
      vm.$store
        .dispatch(GET_ALL_CERTIFICATE_TYPES)
        .then(data => {
          vm.$nextTick(function() {
            vm.types = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getFiles: _.debounce(function() {
      let vm = this;

      let id = this.$route.params.id;
      this.error_messages = [];
      vm.$store
        .dispatch(GET_FILES_BY_TYPE, {
          file_type: "certificate",
          resource_id: id
        })
        .then(data => {
          vm.files = data.data;
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
          }
        });
    }, 500),
    getFile: _.debounce(function(id) {
      let vm = this;
      this.error_messages = [];
      vm.$store
        .dispatch(GET_FILE, id)
        .then(data => {
          vm.streamFile(data.data.code);
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
          }
        });
    }, 500),
    streamFile: function(code) {
      window.open(APP_URL + "file/" + code);
    },
    saveDetail: function() {
      let vm = this;

      vm.$set(
        vm.certificate.details,
        vm.certificate.details.length,
        vm.newDetail
      );

      vm.newDetail = {};
    },

    save: function() {
      let vm = this;

      vm.error_messages = [];

      var action =
        typeof this.$route.params.id !== "undefined"
          ? UPDATE_CERTIFICATE
          : SAVE_CERTIFICATE;

      // if(this.$route.params.id === 'undefined') {
      //   vm.certificate.results = vm.materials.find(po => po.id === vm.certificate.materialId).details;
      // }

      vm.certificate.date = moment(vm.certificate.date).format("YYYY-MM-DD");
      vm.certificate.deliveryDate = moment(vm.certificate.deliveryDate).format(
        "YYYY-MM-DD"
      );

      vm.$store
        .dispatch(action, { payload: vm.certificate })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$nextTick(function() {
            vm.certificate = data.data;
            // if (typeof this.$route.params.id === "undefined") {
            //   vm.$router.push({
            //     name: "certificatesListCreateUpdate",
            //     params: { id: data.data.id }
            //   });
            // }

            vm.$router.push({ name: "certificatesList" });

            // setTimeout(() => {
            //   vm.getMaterial(vm.certificate.materialId);
            // }, 300);
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "certificatesList" });
          }
        });
    },
    getWarehouses: function() {
      let vm = this;
      this.error_messages = [];
      this.$store
        .dispatch(GET_ALL_WAREHOUSES)
        .then(data => {
          vm.$nextTick(function() {
            vm.warehouses = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
          }
        });
    },
    getProducts: function() {
      let vm = this;
      this.$store
        .dispatch(GET_ALL_PRODUCTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.products = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
          }
        });
    },
    getData: function() {
      let vm = this;
      this.error_messages = [];
      let id = this.$route.params.id;
      this.$store
        .dispatch(GET_CERTIFICATE, id)
        .then(data => {
          vm.$nextTick(function() {
            vm.certificate = data.data;

            if (vm.certificate.materialIsArchived) {
              vm.materials.push({
                id: vm.certificate.materialId,
                optionName:
                  vm.certificate.materialType +
                  " - " +
                  vm.certificate.materialName +
                  " (Архивиран)"
              });
            }

            if (vm.certificate.clientIsArchived) {
              vm.clients.push({
                id: vm.certificate.clientId,
                name: vm.certificate.clientName + " (Архивиран)"
              });
            }

            if (vm.certificate.vendorIsArchived) {
              vm.vendors.push({
                id: vm.certificate.vendorId,
                name: vm.certificate.vendorName + " (Архивирана)"
              });
            }
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "certificatesList" });
          }
        });
    },
    handleValidation: function(isValid) {
      this.isSubmitDisabled = !isValid;
    }
  }
};
</script>
